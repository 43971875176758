import React from "react";
import StoryPage from "../../components/story-page";
import { maybeFormatDate } from "../../components/dates";

/** @jsx jsx */
import { jsx, Grid } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl =
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2FWXf47u6NsraKJWnp3miP%2Facbb86a-0632-f33-b0ea-62a4a68a53b?alt=media&token=02e92857-4b67-4082-9313-5b35d97090bd";
  const fullName = `Chan Jin Park | 故 박 찬 진 (朴 贊 珍)`;
  const zoomUrl =
    "https://us02web.zoom.us/j/84947235138?pwd=VFdLRjh2QlJzTWJ0MG5XK1oxV3V1QT09";
  const zoomPhoneNumber = "(646) 558-8656";
  const zoomMeetingId = "849 4723 5138";
  const zoomMeetingPasscode = "854270";
  const birthDate = "";
  const deathDate = "";
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 5 - 1, 8);
  const memorialTime = "7pm US EST";
  const inviteText = `Dear Family and Friends;
  \nOur beloved friend, brother, father and husband Mr. Chan Jin Park passed away on May 8, 2020. Due to the emergence of COVID-19, we were not able to gather together to honor and mark his passing. Instead, our family invites you to join a virtual anniversary memorial service to celebrate his life and to continue his legacy on May 8, 2021 at 7pm-8:30pm (US EST)/May 9 , 2021 at 8am-9:30am (KST).`;
  const inviteChild = ``;
  const obituary = `PARK, Chan Jin of Weston, Massachusetts, passed away peacefully on May 8th, 2020 after a brief illness at the age of 66. Born in Yeongju (영주시), South Korea, he graduated from Seoul National University (서울대학교) and Brown University and was the president of Massachusetts Engineering Group, Inc. for 30 years. He spent his life as a tenacious entrepreneur and devoted family man. He is survived by his wife and his sons Derek and Daniel, with Daniel's fiancée and her family, and his sister, nieces, nephews and extended family in Korea and US.`;
  const storiesRecordUrl = "https://stories.afterword.com/fgs3p4ayo";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rz0vvt1g3e6a4lhk63py0490ut93oi5hgckdd0aq";
  const recordingEmbedDirectUrl = [
    {
      title: "Memorial Recording",
      url: "https://storage.googleapis.com/afterword-static/recordings/Chan%20Jin%20Park%20Recording.mp4",
    },
  ];
  const dropboxUrl = "";
  const birthDeathOverride = (
    <>
      <Grid gap={2} columns={["1fr", "1fr", "1fr 1fr"]}>
        <h4 sx={{ margin: "0", fontWeight: "500" }}>
          Gregorian|陽曆:
          <br />
          September 5, 1953 – May 8, 2020
          <br />
          生 陽曆 1953年 9月 5日
          <br />卒 陽曆 2020年 5月 8日
        </h4>
        <h4 sx={{ margin: "0", fontWeight: "500" }}>
          Lunar|陰曆:
          <br />
          July 27, 1953 - April 16, 2020
          <br />
          生 陰曆 1953年 7月27日
          <br />卒 陰曆 2020年 4月16日
        </h4>
      </Grid>
    </>
  );
  const memorialOverride = (
    <>
      <p sx={{ fontWeight: "500" }}>
        {maybeFormatDate(new Date(2021, 5 - 1, 9), "dddd MMMM Do, YYYY")}
        <br />
        {maybeFormatDate("8am KST", "h:mmA z")}
      </p>
    </>
  );

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathOverride={birthDeathOverride}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      memorialOverride={memorialOverride}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    ></StoryPage>
  );
};

export default ThisPage;
